import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export default function ListCountPagination({ count, page, setPage, size }) {
  const totalPageListCount = Math.ceil(count / size);

  const startPage =
    Math.floor(page / 10) === 0 ? 1 : Math.floor((page - 1) / 10) * 10 + 1;
  const endPage = startPage + 9 < totalPageListCount ? startPage + 10 : null;

  // console.log(
  //   "count: " + count,
  //   "page: " + page,
  //   "totalPageListCount: " + totalPageListCount,
  //   "startPage: " + startPage,
  //   "endPage: " + endPage
  // );

  const currentPageList = [];
  for (let i = startPage; i < startPage + 10 && i < totalPageListCount + 1; i++)
    currentPageList.push(i);

  const pageList = currentPageList.map((pageItem, index) =>
    page === pageItem ? (
      <button
        key={index}
        className="border-b border-gray-500 min-w-12 hover:bg-gray-100"
      >
        {pageItem}
      </button>
    ) : (
      <button
        key={index}
        className=" min-w-12 hover:bg-gray-100"
        onClick={() => setPage(pageItem)}
      >
        {pageItem}
      </button>
    )
  );

  return (
    <nav id="page" className="mt-8 flex justify-center">
      {startPage > 1 && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            title={"1 페이지로"}
            onClick={() => {
              setPage(1);
            }}
          >
            <ChevronDoubleLeftIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}

      {startPage > 1 && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            title={startPage - 1 + " 페이지로"}
            onClick={() => {
              setPage(startPage - 1);
            }}
          >
            <ChevronLeftIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}
      <div className="flex mx-4">{pageList}</div>

      {endPage && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            title={endPage + " 페이지로"}
            onClick={() => {
              setPage(endPage);
            }}
          >
            <ChevronRightIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}
      {totalPageListCount > 10 && totalPageListCount > page && (
        <div>
          <button
            className="hover:bg-gray-50 inline-flex md:p-4 p-2 cursor-pointer dark:hover:bg-gray-800 group"
            // title={data.totalPage + " 페이지로"}
            title={totalPageListCount + " 페이지로"}
            onClick={() => {
              setPage(totalPageListCount);
            }}
          >
            <ChevronDoubleRightIcon className="h-4 text-stone-500 dark:group-hover:text-stone-100" />
          </button>
        </div>
      )}
    </nav>
  );
}
