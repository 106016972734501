import qs from "query-string";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import axios from "axios";
import api from "../lib/api";
import ListPagination from "../components/ListPagination";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleSearchBar from "../components/SimpleSearchBar";
import SearchHeader from "../components/SearchHeader";

export default function SearchSimpleMain() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState();
  const [sort, setSort] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!!location.state && location.state.page) {
      setPage(location.state.page);
      setSize(location.state.size);
      setKeyword(location.state.keyword);
      setSort(location.state.sort);
    }
  }, []);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`BookKeyList`, { page, size, keyword, sort }],
    queryFn: () =>
      axios
        .post(
          `/api/book/search`,
          qs.stringify({ page, size, sort }) + "&" + qs.stringify(keyword),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch(function (error) {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
        }),
    enabled: !!keyword,
    staleTime: 1000 * 60 * 3,
    // refetchOnMount: false,
  });

  const bookList = data?.items?.map((item) => {
    return (
      <div
        key={item.id}
        className="bg-gray-50 hover:bg-sky-50 my-1 p-4 cursor-pointer"
        onClick={() =>
          navigate(`/item/${item.id}`, {
            state: { page, sort, keyword, size, location: location.pathname },
          })
        }
      >
        <div className="flex items-center mb-1 w-full">
          <div className="text-sm px-1 rounded-md bg-gray-500 text-white mr-2">
            {item.grade}
          </div>
          <div className="font-bold">{item.title}</div>{" "}
          <div className="ml-auto text-sm">
            {(item.type === "brl" && (
              <span className="bg-sky-700/20  px-1 rounded-md ">점자</span>
            )) ||
              (item.type === "spe" && (
                <span className="bg-green-700/20  px-1 rounded-md ">음성 </span>
              )) ||
              (item.type === "mag" && (
                <span className="bg-yellow-700/20  px-1 rounded-md ">
                  확대{" "}
                </span>
              ))}
          </div>
        </div>
        <div className="mb-1">
          <div className="text-sm">발행사: {item.publisher}</div>
          <div className="text-sm">저자: {item.creator}</div>
          <div className="text-sm">발행일: {item.pubDate}</div>
          <div className="text-sm">ISBN: {item.isbn}</div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <SearchHeader />
        {/* <div
          className={
            !!data
              ? `xl:flex xl:space-x-2 justify-center w-3/4 md:w-1/2 lg:w-1/3 items-center mx-auto mt-16`
              : // : `xl:flex xl:space-x-2 justify-center w-3/4 md:w-1/2 lg:w-1/3 items-center absolute top-[50vh] left-[50vw] z-50 -translate-x-1/2 -translate-y-1/2`
                `xl:flex xl:space-x-2 justify-center w-3/4 md:w-1/2 lg:w-1/3 items-center mx-auto mt-16`
          }
        > */}
        <div
          className={
            !!data
              ? `xl:flex xl:space-x-2 justify-center w-full md:w-1/2 items-center mx-auto mt-16`
              : `xl:flex xl:space-x-2 justify-center w-full md:w-1/2 items-center mx-auto mt-40`
          }
        >
          <SimpleSearchBar
            setKeyword={setKeyword}
            setPage={setPage}
            state={location.state}
          />
        </div>
        {isLoading ? (
          <div className="text-center mt-16">Loading...</div>
        ) : data === undefined ? (
          <div></div>
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <>
            <div className="text-sm mt-8">
              '{keyword?.keyword}' 검색 결과 {data?.totalCount} 건
            </div>
            <div className="overflow-x-auto">
              <div>{bookList}</div>
            </div>
            <ListPagination data={data} setPage={setPage} />
          </>
        )}
      </div>
    </>
  );
}
