import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function SearchHeader() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const selectedCSS = `p-2 border text-sky-500 border-sky-500 w-full bg-white text-center font-bold`;
  const unselectedCSS = `p-2 border hover:bg-gray-500/20 border-gray-500 w-full bg-white text-center cursor-pointer`;

  useEffect(() => {}, [pathname]);
  return (
    <>
      <div className="font-bold text-2xl my-8">통합 자료 검색</div>
      <div className="flex w-full my-8">
        <div
          onClick={() => navigate("/search/simple")}
          className={
            pathname === "/search/simple" ? selectedCSS : unselectedCSS
          }
        >
          통합 검색
        </div>
        <div
          onClick={() => navigate("/search/detail")}
          className={
            pathname === "/search/detail" ? selectedCSS : unselectedCSS
          }
        >
          상세 검색
        </div>
      </div>
    </>
  );
}
