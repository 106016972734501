import { useNavigate } from "react-router-dom";
import LoginButton from "./components/LoginButton";
import { useRecoilValue } from "recoil";
import { userIdAtom } from "./lib/atom";
import { Bars3Icon } from "@heroicons/react/16/solid";
import { useState } from "react";

export default function Header() {
  const navigate = useNavigate();
  const id = useRecoilValue(userIdAtom);
  const [visible, setVisible] = useState(false);
  return (
    <>
      <div className="bg-gray-100">
        <div className="sm:px-4 lg:w-3/4 md:flex md:mx-auto text-center">
          <div className="flex">
            <div
              className="sm:hidden absolute p-4"
              role="button"
              tabIndex={0}
              onClick={() => setVisible((prev) => !prev)}
            >
              <Bars3Icon className="h-6 text-gray-500"></Bars3Icon>
            </div>
            <div
              className=" hover:bg-gray-200 p-4 cursor-pointer my-auto  mx-auto sm:mx-0"
              onClick={() => navigate("/")}
            >
              📖{" "}
              {/* <span className=" text-xs font-bold">
                대체교과서 목록 검색 시스템
              </span> */}
            </div>
          </div>
          <div className="sm:flex ml-auto" hidden={!visible}>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => navigate("/search/simple")}
            >
              통합 자료 검색
            </div>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => navigate("/item/create")}
            >
              신규 등록
            </div>
            <div
              className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
              onClick={() => navigate("/admin/list")}
            >
              관리자용 목록 검색
            </div>
            {id === "Shai" && (
              <>
                {/* <div
                  className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
                  onClick={() => navigate("/search")}
                >
                  교과서정보관
                </div>
                <div
                  className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
                  onClick={() => navigate("/opensearch")}
                >
                  교과서정보관 공개데이터
                </div> */}
                {/* <div
                  className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
                  onClick={() => navigate("/nlsearch")}
                >
                  Isbn 검색
                </div> */}
                {/* <div
                  className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
                  onClick={() => navigate("/camera")}
                >
                  카메라
                </div> */}
                {/* 
                <div
                  className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
                  onClick={() => navigate("/barcode")}
                >
                  바코드
                </div>
                <div
                  className="hover:bg-gray-200 p-4 cursor-pointer sm:mr-8 "
                  onClick={() => navigate("/barcode2")}
                >
                  바코드2
                </div> */}
              </>
            )}

            <div className="">
              <LoginButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
