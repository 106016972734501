export default function NotFound() {
  return (
    <div className="flex align-middle items-center mt-40 w-full">
      <div className="font-sanserif text-center mx-auto my-auto flex">
        <div className="font-bold text-xl my-auto">404</div>
        <div className="font-bold mx-4 my-auto">|</div>
        <div className="my-auto text-xl font-thin">PAGE NOT FOUND</div>
      </div>
    </div>
  );
}
