import { BrowserRouter, Routes, Route } from "react-router-dom";

import Auth from "./pages/Auth";
import NotFound from "./pages/NotFound";
import BookList from "./pages/BookList";
import HomeLayout from "./components/HomeLayout";
import RequireAuth from "./components/RequireAuth";
import BookItemCreate from "./pages/BookItemCreate";
import CameraSearch from "./pages/CameraSearch";
import BarcodeSearch from "./pages/BarcodeSearch";
import BarcodeScanner from "./pages/BarcodeScanner";
import KotrySearch from "./pages/KotrySearch";
import KotryOpenSearch from "./pages/KotryOpenSearch";
import NlSearch from "./pages/NlSearch";
import BookItem from "./pages/BookItem";
import SearchDetailMain from "./pages/SearchDetailMain";
import SearchSimpleMain from "./pages/SearchSimpleMain";
import BookItemEdit from "./pages/BookItemEdit";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeLayout></HomeLayout>} />
        <Route path="/auth" element={<Auth />} />

        <Route
          path="/search/detail"
          element={
            <HomeLayout>
              <RequireAuth>
                <SearchDetailMain />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/search/simple"
          element={
            <HomeLayout>
              <RequireAuth>
                <SearchSimpleMain />
              </RequireAuth>
            </HomeLayout>
          }
        />
        {/* <Route
          path="/kotrysearch"
          element={
            <HomeLayout>
              <RequireAuth>
                <KotrySearch />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/opensearch"
          element={
            <HomeLayout>
              <RequireAuth>
                <KotryOpenSearch />
              </RequireAuth>
            </HomeLayout>
          }
        /> */}
        <Route
          path="/nlsearch"
          element={
            <HomeLayout>
              <RequireAuth>
                <NlSearch />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route path="/camera" element={<CameraSearch />} />
        {/* <Route path="/barcode" element={<BarcodeSearch />} />
        <Route path="/barcode2" element={<BarcodeScanner />} /> */}
        <Route
          path="/item/:id"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookItem />
              </RequireAuth>
            </HomeLayout>
          }
        />

        <Route
          path="/admin/list"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookList />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/admin/item/:id"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookItemEdit />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route
          path="/item/create"
          element={
            <HomeLayout>
              <RequireAuth>
                <BookItemCreate />
              </RequireAuth>
            </HomeLayout>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
