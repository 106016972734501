import { atom } from "recoil";

export const uploadStateAtom = atom({
  key: "uploadState",
  default: 0,
});

export const idAtom = atom({
  key: "id",
  default: null,
});

export const userIdAtom = atom({
  key: "userId",
  default: null,
});

export const tokenAtom = atom({
  key: "token",
  default: null,
});

export const nameAtom = atom({
  key: "name",
  default: null,
});

export const nickAtom = atom({
  key: "nick",
  default: null,
});
