import qs from "query-string";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../lib/api";
import ListPagination from "../components/ListPagination";
import { useEffect, useRef, useState } from "react";
import MlSearchBar from "../components/NlSearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import { api_address } from "../lib/api_address";
import { nl_key } from "../lib/api_nl_address";
import NlSearchBar from "../components/NlSearchBar";
import ListCountPagination from "../components/ListCountPagination";

export default function NlSearch() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState({});
  const navigate = useNavigate();
  const searchSize = useRef();
  const { state } = useLocation();

  useEffect(() => {
    if (!!state) {
      setKeyword({ isbn: state.isbn });
    }
  }, []);
  useEffect(() => {
    if (Object.keys(keyword).length !== 0) refetch();
  }, [keyword]);

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`NlIsbnList`, { page, size, keyword }],
    queryFn: () =>
      axios
        .get(
          `https://www.nl.go.kr/seoji/SearchApi.do?` +
            qs.stringify({
              cert_key: nl_key,
              result_style: "json",
              page_no: page,
              page_size: size,
              isbn: keyword.isbn,
              title: keyword.title,
              author: keyword.creator,
              publisher: keyword.publisher,
              sort: `PUBLISH_PREDATE`,
              order_by: `desc`,
              //   start_publish_date: `${
              //     keyword.pubDate !== "" && keyword.pubDate + "0101"
              //   }`,
              //   end_publish_date: `${
              //     keyword.pubDate !== "" && keyword.pubDate + "1231"
              //   }`,
            })
          //   {
          //     headers: {
          //       Authorization: "bearer " + api.getToken(),
          //     },
          //   }
        )
        .then((res) => res.data)
        .catch(function (error) {
          if (error.response) {
            // 요청이 전송되었고, 서버는 2xx 외의 상태 코드로 응답했습니다.
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // 요청이 전송되었지만, 응답이 수신되지 않았습니다.
            // 'error.request'는 브라우저에서 XMLHtpRequest 인스턴스이고,
            // node.js에서는 http.ClientRequest 인스턴스입니다.
            console.log(error.request);
          } else {
            // 오류가 발생한 요청을 설정하는 동안 문제가 발생했습니다.
            console.log("Error", error.message);
          }
          console.log(error.config);
        }),
    enabled: false,
  });

  const bookList = data?.docs?.map((item, index) => {
    return (
      <tr
        key={item.index}
        // onClick={() => navigate(`/item/${item.id}`)}
        className="text-center text-xs border-b border-gray-300 border-dashed hover:bg-gray-100 cursor-pointer last-of-type:border-solid *:p-2"
      >
        <td className="text-xs">{String((page - 1) * size + index + 1)}</td>

        <td>{item.TITLE}</td>
        <td>{item.VOL}</td>
        <td>{item.EA_ISBN}</td>
        <td>{item.PUBLISHER}</td>
        <td className="text-ellipsis overflow-hidden text-nowrap w-80 inline-block my-auto">
          {item.AUTHOR}
        </td>
        <td className="text-xs">{item.PUBLISH_PREDATE}</td>
      </tr>
    );
  });

  const downExcel = () => {
    // let parameter = keyword;
    // parameter[`access_token`] = api.getToken();

    // axios
    //   .get(
    //     `/api/book/listToXls`,
    //     { params: parameter },
    //     { responseType: `blob` }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     const url = window.URL.createObjectURL(
    //       new Blob([res.data], { type: res.headers["content-type"] })
    //     );
    //     const link = document.createElement("a");
    //     link.href = url;
    //     // link.setAttribute("download", "test.xlsx");
    //     document.body.appendChild(link);
    //     link.click();
    //     link.remove();
    //   })
    //   .catch((err) => console.log(err));
    //
    //
    const link = document.createElement("a");
    link.href = `${api_address}/api/book/listToXls?${qs.stringify(
      keyword
    )}&access_token=${api.getToken()}`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="font-bold text-2xl my-8">국중ISBN 검색</div>
        <NlSearchBar setKeyword={setKeyword} setPage={setPage} state={state} />
        <div className="flex text-xs items-center mb-1">
          <div>
            "{keyword?.title + keyword?.creator + keyword?.publisher || ""}"
            검색 결과: {data?.TOTAL_COUNT} 건
          </div>
          <div className="ml-auto justify-end flex items-center space-x-2">
            <div
              onClick={() => downExcel()}
              className="border text-green-700 border-green-700 rounded-sm px-2 cursor-pointer hover:bg-green-700/20"
            >
              엑셀 저장
            </div>
            <div>페이지당 출력수</div>
            <select
              id="size"
              ref={searchSize}
              onChange={() => setSize(searchSize.current.value)}
              className="h-6 focus:outline-none focus:ring-2 focus:ring-blue-500 mx-1 border border-[#e5e7eb]"
            >
              <option name="size" value={10} defaultValue={size === 10}>
                10건
              </option>
              <option name="size" value={20} defaultValue={size === 20}>
                20건
              </option>
              <option name="size" value={50} defaultValue={size === 50}>
                50건
              </option>
              <option name="size" value={100} defaultValue={size === 100}>
                100건
              </option>
            </select>
          </div>
        </div>
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="mx-auto w-full table-auto">
                <thead>
                  <tr className="bg-gray-200 text-center *:bg-gray-200 *:py-2 *:text-sm *:font-bold ">
                    <td>No.</td>
                    <td className="w-1/4">도서명</td>
                    <td className="w-20">권차</td>
                    <td className="w-20">isbn</td>
                    <td className="w-36">발행사</td>
                    <td className="">저자</td>
                    <td>발행일</td>
                  </tr>
                </thead>
                <tbody>{bookList}</tbody>
                <tfoot></tfoot>
              </table>
            </div>
            {data?.resultCode &&
              "ERR " + data?.resultCode + ": " + data?.resultMsg}
            {/* <ListPagination data={data} setPage={setPage} /> */}
            <ListCountPagination
              count={data?.TOTAL_COUNT}
              size={size}
              setPage={setPage}
              page={page}
            />
          </>
        )}
      </div>
    </>
  );
}
