import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import qs from "query-string";

export default function Auth() {
  const { search } = useLocation();

  useEffect(() => {
    const code = qs.parse(search).code;
    if (code) {
      window.opener.postMessage({ code }, window.location.origin);
    }
    window.close();
  }, [search]);

  return <div className="text-center">Login 중 입니다...</div>;
}
