import { CameraIcon } from "@heroicons/react/16/solid";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

export default function SimpleSearchBar({ setKeyword, setPage, state }) {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm();
  const onSubmit = (data) => {
    setKeyword(data);
    setPage(1);
  };

  useEffect(() => {
    if (!!state && state.isbn) {
      setValue("keyword", state.isbn);
      onSubmit({ keyword: state.isbn });
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full lg:flex">
        <div className="flex space-x-2 w-full">
          <div className="flex justify-center items-center relative w-full ">
            <input
              {...register("keyword")}
              className="bg-white w-full border border-slate-200 rounded-md p-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
            ></input>
            <CameraIcon
              onClick={() =>
                navigate(`/camera`, {
                  state: { location: location.pathname },
                })
              }
              className="h-9 p-1 text-gray-400 rounded-md right-2 hover:text-sky-500 absolute"
            />
          </div>
          <input
            type="submit"
            value="검색"
            className="bg-sky-500 rounded-md text-white font-bold px-3 cursor-pointer w-28"
          />
        </div>
        {/* <div
          onClick={() => navigate("/search/detail")}
          className="mt-4 xl:mt-0 xl:ml-2 w-28 text-center border border-sky-500 rounded-md text-sky-500 font-bold p-3 no-wrap cursor-pointer"
        >
          상세검색
        </div> */}
      </form>
    </>
  );
}
